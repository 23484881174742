import React, { useEffect, useState } from "react";
import Clock from "react-clock";
import PropTypes from "prop-types";

const ContactItem = (props) => {
  const { city, street, region, timeOffset } = props;

  const [date, setDate] = useState();

  useEffect(() => {
    const calOffsets = () => {
      setDate(getDateByOffset(timeOffset));
    };

    const getDateByOffset = (offset) => {
      const nowlocalDate = new Date();
      return new Date(
        nowlocalDate.getTime() +
          (nowlocalDate.getTimezoneOffset() + offset * 60) * 60000
      );
    };
    calOffsets();

    let intervalHandle = setInterval(() => {
      calOffsets();
    }, 1000);

    return () => {
      clearInterval(intervalHandle);
    };
  }, [timeOffset]);

  return (
    <div className="contact-item">
      <div className="contact-item__clock">
        <Clock
          value={date}
          renderHourMarks={false}
          renderMinuteMarks={false}
          renderSecondHand={true}
          hourHandWidth={1}
          hourHandLength={60}
          minuteHandLength={90}
          minuteHandWidth={1}
          size={15}
        />
      </div>
      <div className="contact-item__address">
        <div className="city">{city}</div>

        <address>
          <span>{street}</span>
          <span>{region}</span>
        </address>
      </div>
    </div>
  );
};

ContactItem.propTypes = {
  imagePath: PropTypes.object.isRequired,
  isReversed: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  street: PropTypes.string.isRequired,
  region: PropTypes.string.isRequired,
  contactName: PropTypes.string.isRequired,
  contactEmail: PropTypes.string.isRequired,
  timestamp: PropTypes.string.isRequired,
  timezone: PropTypes.string.isRequired,
};

export default ContactItem;
