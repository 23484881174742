import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { Controller, useForm } from "react-hook-form";
import { Select, TextInput } from "../forms";
import { countryCodes, referralOptions } from "../../lib/const";
import { useHubSpotCookie, useIpAddress } from "../../lib/hooks";

const ContactForm = ({ enableCheckbox }) => {
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const hubSpotCookie = useHubSpotCookie();
  const ipAddress = useIpAddress();

  const {
    reset,
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm();

  const countryOptions = countryCodes.map((country) => ({
    name: `${country.code}: ${country.dial_code}`,
    value: `${country.code}: ${country.dial_code}`,
    code: country.dial_code,
  }));

  const onSubmit = async (formData) => {
    try {
      const res = await fetch("/api/hubspot-lead", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...formData,
          hubSpotCookie,
          ipAddress,
          pageUri: document.URL,
          pageName: document.title,
        }),
      });
      if (!res.ok) {
        throw new Error("Failed to create a lead");
      }
      const data = await res.json();

      if (data.error) {
        throw new Error(data.error);
      }

      setSuccessMessage(data.message ?? "Thank you for submitting!");
      setTimeout(() => {
        setSuccessMessage("");
        reset();
      }, 5000);
    } catch (error) {
      setErrorMessage(
        "There was an error submitting the form. Please try again."
      );
      setTimeout(() => {
        setErrorMessage("");
      }, 5000);
      console.error("Error creating a contact:", error.message);
    }
  };

  const defaultValues = {
    firstname: "Dev",
    lastname: "Test",
    email: "team@halfhelix.com",
    organization: "Half Helix",
    countryCode: "+1",
    phone: "555-555-5555",
    current_ecommerce_platform: "Salesforce",
    referral: "Shopify Partner Directory",
    project_info: "This project will be...",
    consentPolicy: true,
  };

  // Just a nice way to test the form easily
  const useDefaultValues = process.env.GATSBY_ENVIRONMENT === "development";

  return (
    <div className="contact-form">
      {/* data-hs-do-not-collect is a HubSpot attribute that prevents the form form being automatically collected */}
      <form onSubmit={handleSubmit(onSubmit)} data-hs-do-not-collect>
        <div className="contact-form__half-width">
          <TextInput
            label="First Name"
            name="firstname"
            register={register}
            errors={errors}
            defaultValue={useDefaultValues ? defaultValues.firstname : ""}
            validation={{ required: "This field is required" }}
          />
          <TextInput
            label="Last Name"
            name="lastname"
            register={register}
            errors={errors}
            defaultValue={useDefaultValues ? defaultValues.lastname : ""}
            validation={{ required: "This field is required" }}
          />
        </div>
        <TextInput
          type="email"
          label="Work Email"
          name="email"
          register={register}
          errors={errors}
          defaultValue={useDefaultValues ? defaultValues.email : ""}
          validation={{
            required: "This field is required",
          }}
        />
        <TextInput
          label="Company Name/Organization"
          name="company"
          register={register}
          errors={errors}
          defaultValue={useDefaultValues ? defaultValues.organization : ""}
          validation={{ required: "This field is required" }}
        />

        <div className="form-phone">
          <div className="form-phone__country">
            <Controller
              name="countryCode"
              control={control}
              defaultValue={
                countryOptions.find((country) => country.name.includes("US: "))
                  ?.value
              }
              render={({ field: { value, onChange, name } }) => (
                <Select
                  label="Country"
                  options={countryOptions}
                  onChange={(val) => onChange(val)}
                  selectedValue={value}
                  errors={errors}
                  name={name}
                />
              )}
            />
          </div>

          <TextInput
            label="Phone Number"
            name="phone"
            type="tel"
            register={register}
            errors={errors}
            pattern="[0-9\-]*"
            defaultValue={useDefaultValues ? defaultValues.phone : ""}
            validation={{
              required: "This field is required",
            }}
          />
        </div>
        <TextInput
          label="Current eCommerce Platform"
          name="current_ecommerce_platform"
          register={register}
          errors={errors}
          defaultValue={
            useDefaultValues ? defaultValues.current_ecommerce_platform : ""
          }
          validation={{ required: "This field is required" }}
        />
        <Controller
          name="referral"
          control={control}
          render={({ field: { value, onChange, name } }) => (
            <Select
              label="How did you hear about us? (Optional)"
              options={referralOptions}
              onChange={(val) => onChange(val)}
              selectedValue={value}
              defaultValue={useDefaultValues ? defaultValues.referral : ""}
              errors={errors}
              name={name}
            />
          )}
        />

        <div className="form-textarea">
          <label
            className={`form-label ${
              errors?.project_info ? "form-label--error" : ""
            }`}
            htmlFor="project_info"
          >
            Project Info
          </label>
          <textarea
            className={`form-control ${
              errors?.project_info ? "form-control--error" : ""
            }`}
            name="project_info"
            placeholder="Tell us about your project..."
            defaultValue={useDefaultValues ? defaultValues.project_info : ""}
            rows={5}
            {...register("project_info", {
              required: "This is required",
            })}
          />
          {errors?.project_info && (
            <span className="form-error">{errors?.project_info?.message}</span>
          )}
        </div>

        {enableCheckbox && (
          <div
            className={`form-checkbox ${
              errors?.consent_policy ? "is-invalid" : ""
            }`}
          >
            <input
              type="checkbox"
              id="consent_policy"
              name="consent_policy"
              {...register("consent_policy", {
                required: "This is required",
              })}
            />
            <label htmlFor="consent_policy">
              I consent to the use of my data according to the{" "}
              <Link to="/privacy-policy">Privacy Policy</Link>.
            </label>
            {errors?.consent_policy && (
              <span className="form-error">
                {errors?.consent_policy?.message}
              </span>
            )}
          </div>
        )}

        <button className="form-submit" disabled={isSubmitting}>
          Submit
        </button>
      </form>

      {(successMessage || errorMessage) && (
        <div
          className={`contact-form__message contact-form__message--${
            successMessage ? "success" : "error"
          }`}
          tabIndex={0}
        >
          <span className="contact-form__success-square"></span>
          {successMessage || errorMessage}
        </div>
      )}
    </div>
  );
};

export default ContactForm;
