import React from "react";
import PropTypes from "prop-types";
import ContactItem from "./contact-item";

const ContactList = ({ items }) => {
  return (
    <div className="contact-list">
      {items.map((child, idx) => {
        return (
          <ContactItem
            key={idx}
            imagePath={child.banner}
            isReversed={child.isReversed || ""}
            city={child?.city}
            street={child.address1 + " " + (child.address2 || " ")}
            region={child.region + " " + (child.postCode || " ")}
            contactName={child.contact ? child.contact.name : ""}
            contactEmail={child.email}
            timestamp={child.timeStamp}
            timezone={child.timeZone}
            timeOffset={child.timeOffset}
          />
        );
      })}

      <div className="contact__follow">
        <div>Follow Us</div>

        <ul
          className="listreset contact__follow__list footer-social"
          itemScope
          itemType="http://schema.org/ItemList"
        >
          <li itemProp="itemListElement">
            <a
              href="https://twitter.com/halfhelix?lang=en"
              tabIndex="0"
              target="_blank"
              rel="noopener noreferrer"
            >
              Twitter
            </a>
          </li>
          <li itemProp="itemListElement">
            <a
              href="https://github.com/halfhelix"
              tabIndex="0"
              target="_blank"
              rel="noopener noreferrer"
            >
              Github
            </a>
          </li>
          <li itemProp="itemListElement">
            <a
              href="https://www.instagram.com/halfhelix/?hl=en"
              tabIndex="0"
              target="_blank"
              rel="noopener noreferrer"
            >
              Instagram
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

ContactList.propTypes = {
  items: PropTypes.instanceOf(Array).isRequired,
};

export default ContactList;
