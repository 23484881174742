import { useEffect, useState } from "react";

export const useHubSpotCookie = () => {
  const [hubSpotCookie, setHubSpotCookie] = useState("");
  useEffect(() => {
    const getHubSpotCookie = async () => {
      const cookie = document.cookie;
      const cookieName = "hubspotutk";
      const cookieArr = cookie.split(";");
      const cookieValue = cookieArr.find((item) => item.includes(cookieName));
      if (cookieValue) {
        const cookieValueArr = cookieValue.split("=");
        setHubSpotCookie(cookieValueArr[1]);
      }
    };
    getHubSpotCookie();
  }, []);
  return hubSpotCookie;
};

export const useIpAddress = () => {
  const [ipAddress, setIpAddress] = useState("");
  useEffect(() => {
    const getIpAddress = async () => {
      const res = await fetch("/api/ip");
      const data = await res.json();
      setIpAddress(data.ip);
    };
    getIpAddress();
  }, []);
  return ipAddress;
};
