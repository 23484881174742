// eslint-disable-next-line
/* eslint-disable */
import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import { graphql, Link } from "gatsby";
import { navigate } from "gatsby-link";

import Layout from "../components/layout";
import Seo from "../components/seo";
import Headline from "../components/text-elements/headline";
import ContactList from "../components/contactpage/contact-list";
import ContactForm from "../components/contactpage/contact-form";

const ContactPage = ({ data }) => {
  const { contact } = data.takeshape;

  if (
    (!contact.enabled && process.env.GATSBY_ENVIRONMENT !== "staging") ||
    (!contact.enableOnStaging && process.env.GATSBY_ENVIRONMENT === "staging")
  ) {
    if (typeof window !== "undefined") {
      navigate("/");
    }
  }

  return (
    <>
      <Helmet
        bodyAttributes={{
          class: "footer-offset alt-header",
        }}
      />
      <Layout headerClass="header--dark">
        <Seo title={contact.pageTitle} description={contact.pageDescription} />
        <Headline
          customClass={"contact-headline__wrap"}
          headline={contact.title}
          newClass={"contact-headline"}
          isContactHeadline={true}
        >
          <div className="h1">
            <div className="mobile-headline desktop-hide">
              <div className="mail-headline">
                {contact.mobileTitle}{" "}
                <a href={`mailto:${contact.mobileEmail}`}>
                  {contact.mobileEmail}
                </a>
              </div>
              <div>{contact.mobileSubTitle}</div>
            </div>
            <span className="mobile-hide">
              {contact.mailTo.map((mail, idx) => {
                return (
                  <Fragment key={idx}>
                    {mail.link.includes("@") ? (
                      <a href={`mailto:${mail.link}`} tabIndex="0">
                        {mail.name}
                      </a>
                    ) : (
                      <Link to={mail.link}>{mail.name}</Link>
                    )}
                    {contact.mailTo.length - 1 !== idx && <span>,&nbsp;</span>}
                  </Fragment>
                );
              })}
            </span>
          </div>
        </Headline>
        <div className="container container--bordered container--fluid">
          <div className="contact-container">
            <div className="contact">
              <ContactList items={contact.contactList} />
              <ContactForm enableCheckbox={contact.enableCheckbox} />
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default ContactPage;

export const query = graphql`
  query {
    takeshape {
      contact: getContact {
        _id
        enabled
        enableOnStaging
        enableCheckbox
        mobileEmail
        mobileSubTitle
        mobileTitle
        title
        pageTitle
        pageDescription
        missionTitle
        missionDescription
        contactList {
          banner {
            path
            title
          }
          city
          contact {
            name
            email
          }
          isReversed
          region
          address1
          address2
          postCode
          timeStamp
          timeZone
          timeOffset
          email
        }
        mailTo {
          name
          link
        }
      }
    }
  }
`;
